.s-btn-home{
    font-size: 0.675rem;
    font-weight: 400;
    padding: 0.50rem 1rem;
    width: 100px;
  }

  .margin-10 {
      margin: 10px;
  }
  .padding-5 {
    padding: 5px;
}

.gallery-thumbnail {
    margin-bottom: 5px;
    margin-top: 4px;
    margin-left: 5px;
    border-style: solid;
    // border-color: white;
    // border-width: .5px; 
    box-shadow: 0 15px 50px rgba(0,0,0,.5);
    // transform: matrix(1, 0, 0, 1, 0, 0);
    transition: transform 0.15s ease 0s;
    // transform: scale3d(1, 1, 1);
    // display: inline-block;
    // overflow: hidden;
}

.gallery-thumbnail:hover {
    transform: scale(1.05);
}

.gallery-link {
    display: inline-block;
    overflow: hidden;
}

.iframe-360 {
    width: 100%;
    min-height: 600px;
}


.video-container {
    padding-top: 20px;
    margin: 0 auto;
    width: 800px;
    align: center;
  }